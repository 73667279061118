import { Injectable } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EventService {
  public channels: { [key: string]: Subject<any>; } = {};

  constructor() { }

  
  publish(topic: string, data: any): void {
    const subject = this.channels[topic];
    if (!subject) {
        // Or you can create a new subject for future subscribers
        return;
    }

    subject.next(data);
}

}
